import 'owl.carousel';

jQuery(document).ready(function ($) {

  // MAIN CAROUSEL
  function createMainCarousel() {
    const mainCarousel = $("#main-carousel");
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-light fa-circle-arrow-left"></i></div>';
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-light fa-circle-arrow-right"></i></div>';

    mainCarousel.owlCarousel({
      loop: true,
      nav: true,
      dots: false,
      items: 1,
      startPosition: 0,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplaySpeed: 500,
      autoplayHoverPause: true,
      margin: 55,
      center: true,
      navText: [
        leftArrow,
        rightArrow
      ],
    });
  }
  createMainCarousel();


  // CLIENTS HOVER
  var $images = $('.client-item img');
  var randomIndex = Math.floor(Math.random() * $images.length);
  $images.eq(randomIndex).css('opacity', '1');


  $('.client-item p').on('mouseenter', function () {
    $('.client-item img').css('opacity', '0');
    $(this).siblings('img').css('opacity', '1');
  });


  // Check if the user has already visited the site and if the timer has expired
  const visited = localStorage.getItem('visited');
  const now = new Date().getTime();
  const tenMinutes = 10 * 60 * 1000; // 10 minutes in milliseconds

  if (!visited || now - visited > tenMinutes) {
    $('#loading-screen').show();

    setTimeout(function () { 
      $('#main-content').hide();
      $('#loading-screen').fadeOut(500, function () {
        $(this).css('display', 'none');
        $('#main-content').fadeIn(500);
      });
    }, 3000);

    localStorage.setItem('visited', now);
  }
 
  if ($("body").hasClass('paged') && $("body").hasClass('page-template-single-service')) {
    $('html, body').animate({ scrollTop: $('#case-studies').offset().top }, 'slow');
  }
});
